import React from 'react'
import "./QuranRecite.scss"
import { FaPlay } from 'react-icons/fa';
import PrimaryButton from 'Components/Atoms/PrimaryButton/PrimaryButton';
import HeaderImage from "../../Assets/Images/recite/Header-picture.png"
const QuranRecite = () => {

    const subscription = async (e) => {
        e.preventDefault();


    };


    return (
        <>
            <div className="cardFull">
                <div className="topImg">
                    <div className='imgData'>
                        <h2>Best Free Quran Recitations Online</h2>
                        <p>Listen to the best Quran recitations with Tajweed online by the top reciters & sheikhs across the history.
                            Moreover, you can start learning to recite the Quran by listening and repeat after the reciters</p>
                    </div>
                </div>

                <div className="mainContainer">
                    <div className="card">
                        <div className="topData">
                            <dvi className="image">
                                <img src="https://png.pngtree.com/png-clipart/20190925/ourmid/pngtree-golden-quran-cover-ornament-mushaf-logo-with-islamic-frame-png-image_1731057.jpg" alt="" />
                            </dvi>

                            <dvi className="heading">
                                <p>QURAN RECITATION BY</p>
                                <h3>
                                    Sheikh Abdul Basit Abdul Samad
                                </h3>
                            </dvi>
                            <dvi className="details">
                                <p>Sheikh Abdul Basit Abdul Samad is one of the best reciters of the Quran in the world, he was an Egyptian Qari, Sheikh and Imam. He had won 3 world Qiraat competitions in the early 1970s.</p>
                            </dvi>
                        </div>
                        <div className='mediaContainer'>
                            <iframe src="https://w.soundcloud.com/player/?url=https://soundcloud.com/telawatcloud/sets/abdulbasithafs?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" frameborder="0"></iframe>
                        </div>

                    </div>


                </div>

                <div className="mainContainer">
                    <div className="card">
                        <div className="topData">
                            <dvi className="image">
                                <img src="https://png.pngtree.com/png-clipart/20190925/ourmid/pngtree-golden-quran-cover-ornament-mushaf-logo-with-islamic-frame-png-image_1731057.jpg" alt="" />
                            </dvi>

                            <dvi className="heading">
                                <p>QURAN RECITATION BY</p>
                                <h3>
                                    Sheikh Mishary bin Rashid Alafasy
                                </h3>
                            </dvi>
                            <dvi className="details">
                                <p>Sheikh Mishary is a Qari, imam, preacher, and Nasheed artist. He was born in Kuwait. He studied in the Islamic University of Madinah’s College of Qur’an, specializing in the ten qira’at and tafsir.</p>
                            </dvi>
                        </div>
                        <div className='mediaContainer'>
                            <iframe src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1704456&color=%23ffb606&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" frameborder="0"></iframe>
                        </div>

                    </div>

                </div>

                <div className="mainContainer">
                    <div className="card">
                        <div className="topData">
                            <dvi className="image">
                                <img src="https://png.pngtree.com/png-clipart/20190925/ourmid/pngtree-golden-quran-cover-ornament-mushaf-logo-with-islamic-frame-png-image_1731057.jpg" alt="" />
                            </dvi>

                            <dvi className="heading">
                                <p>QURAN RECITATION BY</p>
                                <h3>
                                    Sheikh Mahmoud Khalil Al-Hussary
                                </h3>
                            </dvi>
                            <dvi className="details">
                                <p>Sheikh Mahmoud Khalil Al-Hussary is an an Egyptian Qur’ân reciter. He was born in in 1917 and died in 1980. At age 8, he memorized the whole Qur’ân, and at 12, he learned the ten qira’aat (recitations).</p>
                            </dvi>
                        </div>
                        <div className='mediaContainer'>
                            <iframe src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/252248844&color=%23ffb606&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" frameborder="0"></iframe>
                        </div>

                    </div>


                </div>

                <div className="mainContainer">
                    <div className="card">
                        <div className="topData">
                            <dvi className="image">
                                <img src="https://png.pngtree.com/png-clipart/20190925/ourmid/pngtree-golden-quran-cover-ornament-mushaf-logo-with-islamic-frame-png-image_1731057.jpg" alt="" />
                            </dvi>

                            <dvi className="heading">
                                <p>QURAN RECITATION BY</p>
                                <h3>
                                    Sheikh Mahmud Ali Al-Banna
                                </h3>
                            </dvi>
                            <dvi className="details">
                                <p>Sheikh Mahmud is an an Egyptian Qur’ân reciter. He was born in in 1926 and died in 1985. He memorized the Holy Qur’an at the age of 11. In 1948, he became of the most acclaimed reciters of Egypt.</p>
                            </dvi>
                        </div>
                        <div className='mediaContainer'>
                            <iframe src="https://w.soundcloud.com/player/?url=https://soundcloud.com/txxeoqbyxttf/sets/drdeyxlotwig?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" frameborder="0"></iframe>
                        </div>

                    </div>


                </div>

                <div className="mainContainer">
                    <div className="card">
                        <div className="topData">
                            <dvi className="image">
                                <img src="https://png.pngtree.com/png-clipart/20190925/ourmid/pngtree-golden-quran-cover-ornament-mushaf-logo-with-islamic-frame-png-image_1731057.jpg" alt="" />
                            </dvi>

                            <dvi className="heading">
                                <p>QURAN RECITATION BY</p>
                                <h3>
                                    Sheikh ِAbdullah Aljahny
                                </h3>
                            </dvi>
                            <dvi className="details">
                                <p>Sheikh Abdullah Aljahny is a Saudi Qur’ân reciter and imam. He memorized the entire Quran in 1990 when he was 22 years old. He is often noted for his acclaimed Tajweed.</p>
                            </dvi>
                        </div>
                        <div className='mediaContainer'>
                            <iframe src="https://w.soundcloud.com/player/?url=https://soundcloud.com/mrwan560/sets/7g5nyoq2vzav?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharingutm_source=clipboard&utm_medium=text&utm_campaign=social_sharing=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" frameborder="0"></iframe>
                        </div>

                    </div>


                </div>

                <div className="mainContainer">
                    <div className="card">
                        <div className="topData">
                            <dvi className="image">
                                <img src="https://png.pngtree.com/png-clipart/20190925/ourmid/pngtree-golden-quran-cover-ornament-mushaf-logo-with-islamic-frame-png-image_1731057.jpg" alt="" />
                            </dvi>

                            <dvi className="heading">
                                <p>QURAN RECITATION BY</p>
                                <h3>
                                    Sheikh Maher Al Mueaqly
                                </h3>
                            </dvi>
                            <dvi className="details">
                                <p>Sheikh Saad Al Ghamdi is a Saudi Qur’ân reciter and imam. He holds a master’s degree in “the jurisprudence of Imam Ahmad bin Hanbal” and received a doctorate in “Quran Tafsir (interpretation)”</p>
                            </dvi>
                        </div>
                        <div className='mediaContainer'>
                            <iframe src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/105251136&color=%23ffb606&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" frameborder="0"></iframe>
                        </div>

                    </div>


                </div>

                <div className="mainContainer">
                    <div className="card">
                        <div className="topData">
                            <dvi className="image">
                                <img src="https://png.pngtree.com/png-clipart/20190925/ourmid/pngtree-golden-quran-cover-ornament-mushaf-logo-with-islamic-frame-png-image_1731057.jpg" alt="" />
                            </dvi>

                            <dvi className="heading">
                                <p>QURAN RECITATION BY</p>
                                <h3>
                                    Sheikh Mohamed Siddiq El-Minshawi
                                </h3>
                            </dvi>
                            <dvi className="details">
                                <p>Sheikh Mohamed Seddik El-Menshawy is a famous Egyptian Qur’anic reciter and imam. He is also the author of many books on various aspects of the Quran.</p>
                            </dvi>
                        </div>
                        <div className='mediaContainer'>
                            <iframe src="https://w.soundcloud.com/player/?url=https://soundcloud.com/nancy-elkaluoby/sets/obwwwengzouq?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" frameborder="0"></iframe>
                        </div>

                    </div>


                </div>

                <div className="mainContainer">
                    <div className="card">
                        <div className="topData">
                            <dvi className="image">
                                <img src="https://png.pngtree.com/png-clipart/20190925/ourmid/pngtree-golden-quran-cover-ornament-mushaf-logo-with-islamic-frame-png-image_1731057.jpg" alt="" />
                            </dvi>

                            <dvi className="heading">
                                <p>QURAN RECITATION BY</p>
                                <h3>
                                    Sheikh Bandar Balilah
                                </h3>
                            </dvi>
                            <dvi className="details">
                                <p>Sheikh Bandar Baleelah is a Saudi Qur’ân reciter. He is an imam of the Masjid Al-Haram in Mecca. Obtained a degree in Islamic from UmAl Qura University.</p>
                            </dvi>
                        </div>
                        <div className='mediaContainer'>
                            <iframe src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/502044888&color=%23ffb606&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" frameborder="0"></iframe>
                        </div>

                    </div>


                </div>
            </div>
        </>
    )
}

export default QuranRecite;